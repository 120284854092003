import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Chip } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface ColorPalette {
    colorHexCodes: string[];
}

interface ColorPaletteSelectorProps {
    colorPalettes: ColorPalette[];
    activeColorPalette: string[];
    setColorPalette: (palette: string[]) => void;
}

const ColorPaletteSelector: React.FC<ColorPaletteSelectorProps> = (props) => {
    const { colorPalettes } = props;
    const [activePalette, setActivePalette] = useState<string[]>(props.activeColorPalette);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedPalette = event.target.value.split(',') as string[];
        props.setColorPalette(selectedPalette);
        setActivePalette(selectedPalette);
    };

    return (
        <FormControl variant="standard" sx={{ m: 1 }}>
            <Select
                size='small'
                labelId="color-palette-label"
                value={activePalette.join(',')}
                onChange={handleChange}
                renderValue={() => 'Color'}
            >
                {colorPalettes.map((palette, paletteIndex) => (
                    <MenuItem 
                    key={paletteIndex} value={palette.colorHexCodes.join(',')}
                    >
                        <div style={{ display: 'flex', gap: '2px' }}>
                            {palette.colorHexCodes.map((color, colorIndex) => (
                                <Chip
                                    key={colorIndex}
                                    style={{ backgroundColor: color, height: '18px', width: '18px' }}
                                />
                            ))}
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};


export default ColorPaletteSelector;
