import React from 'react';
import './PolygonInnerText.css';

interface PolygonInnerTextProps {
    text: string;
}

const getFill = (index: number, line: string): string => {
    if (index === 2) {
        if (line.includes("+")) {
            return "#19cc19";
        } else if (line.includes("-")) {
            return "red";
        }
    }
    return "black";
}

const getFontSize = (index: number, arrayLength: number): string => {
    if (arrayLength === 2) {
        return "medium";
    } else if (arrayLength >= 3) {
        const lineSizes: string[] = ["xx-small", "80%", "36%"];
        return lineSizes[index];
    }
    return "medium";
}

const getTempLineMargin = (arrayLength: number): number => {
    return arrayLength >= 3 ? 20 : 26;
}

const PolygonInnerText: React.FC<PolygonInnerTextProps> = (props) => {
    return (
        <>
            {
                props.text.split("\n").map((line, index, array) => {

                    const fill = getFill(index, line);
                    const fontSize = getFontSize(index, array.length);
                    const tempLineMargin = getTempLineMargin(array.length);

                    return <text
                        className="PolygonInnerText-textElement"
                        x={"50%"}
                        y={(50 - ((array.length - 1) * (tempLineMargin / 2)) + (tempLineMargin * (index))) + "%"}
                        fill={fill}
                        fontSize={fontSize}
                    >{line}</text>;
                })
            }
        </>
    );
}

export default PolygonInnerText;