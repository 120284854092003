export const MOBILE_SCREEN_WIDTH: number = 768;

export function isMobile(): boolean {
    const screenWidth: number = window.innerWidth;
    const check: boolean = screenWidth <= MOBILE_SCREEN_WIDTH; // Adjust the threshold value as needed
    return check;
}

export function getCurrencyDisplayScreenWidthRatio(innerWidth: number, innerHeight: number): string {
    const screenWidth: number = window.screen.width;
    const heightToWidthRatio: number = innerHeight / screenWidth;
    let calculatedWidth: number = 100;
    if (heightToWidthRatio < 1.0) {
        calculatedWidth *= 0.9 * heightToWidthRatio;
    } else {
        calculatedWidth *= 0.9;
    }
    return calculatedWidth + "%";
}