import React from 'react';
import { Button } from '@mui/material';
import { CURRENCY_RATE_DIVISION_TYPES } from '../../../constants/DisplayTypes';

interface CurrencySwitchButtonProps {
    currencyDivisionType: CURRENCY_RATE_DIVISION_TYPES;
    setCurrencyDivisionType: (type: CURRENCY_RATE_DIVISION_TYPES) => void;
}

const CurrencySwitchButton: React.FC<CurrencySwitchButtonProps> = ({ currencyDivisionType, setCurrencyDivisionType }) => {
    return (
  
            <Button
                variant="outlined"
                onClick={() =>
                    setCurrencyDivisionType(
                        currencyDivisionType === CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION ?
                            CURRENCY_RATE_DIVISION_TYPES.CONVERSION_DIV_MAIN :
                            CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION)}
            >
                {"Inverse the rate"}
            </Button>
        
    );
}

export default CurrencySwitchButton;