import { ColorPalette } from "../types/ColorTypes";

export const GridAreaContrastColors = [
    "#F57677",
    "#FF8000",
    "#7681F5",
    "#F576D5",
    "#48B6E1",
    "#76F599"

];

export const GridAreaSimilarColors = [
    "#5DA9F0",
    "#3D6BE0",
    "#4C50E1",
    "#3CA0E1",
    "#6B77E1",
    "#8AC0E3"
];

export const CentralBlockColor = "#b6d1d4";

export const ALL_COLOR_PALETTES: ColorPalette[] = [
    { colorHexCodes: GridAreaContrastColors },
    { colorHexCodes: GridAreaSimilarColors }
];
