import React, { useEffect, useState } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import CurrencyGridDisplay from "../../organisms/CurrencyGridDisplay/CurrencyGridDisplay";
import CurrencySearchSelection from "../../molecules/CurrencySearchSelection/CurrencySearchSelection";
import CurrencyMultipleSearchSelection from "../../molecules/CurrencyMultipleSearchSelection/CurrencyMultipleSearchSelection";
import CurrencyCenteredDisplay from "../../organisms/CurrencyCenteredDisplay/CurrencyCenteredDisplay";
import ECBDataSourceInformer from "../../atoms/ECBDataSourceInformer/ECBDataSourceInformer";
import HexagonalDisplayTypeMenu from "../../atoms/HexagonalDisplayTypeMenu/HexagonalDisplayTypeMenu";
import ColorPaletteSelector from "../../molecules/ColorPaletteSelector/ColorPaletteSelector";
import CookiePolicyMessage from "../../molecules/CookiePolicyMessage/CookiePolicyMessage";
import CurrencySwitchButton from "../../atoms/CurrencySwitchButton/CurrencySwitchButton";
import DateInfoHeader from "../../atoms/DateInfoHeader/DateInfoHeader";

import { HexagonalDisplayType } from "../../../Util/PolygonUtil";
import { isMobile } from "../../../Util/DeviceUtil";
import {
    CurrencyVisualizationData,
    ExchangeRateNode,
    CurrencySourceData,
    CurrencyPoolOption,
} from "../../../types/CurrencyTypes";
import { getCurrencyExplanation } from "../../../constants/CurrencyInfo";
import {
    GridAreaSimilarColors,
    ALL_COLOR_PALETTES,
} from "../../../constants/DisplayColors";
import { DEFAULT_UNIT_POLYGON } from "../../../constants/DefaultUnitPolygon";
import {
    INITIAL_DISPLAY_CURRENCY_LIST,
    INITIAL_CENTRAL_CURRENCY,
} from "../../../constants/CurrencyInfo";
import {
    CURRENCY_RATE_DIVISION_TYPES,
    SELECTABLE_CURRENCY_DISPLAY_TYPES,
} from "../../../constants/DisplayTypes";
import useLocalStorage from "../../../hooks/useLocalStorage";
import ColorTheme from "../../../constants/ColorThemes";

type CurrencyManagerProps = {
    mode: ColorTheme;
    setMode: (mode: ColorTheme) => void;
};

const CURRENCY_DISPLAY_TYPE_COOKIE = "currencyDisplayTypeCookie";
const COLOR_PALETTE_COOKIE = "colorPaletteCookie";
const IS_COOKIE_POLICY_DISMISSED = "isCookiePolicyDismissed";
const CURRENCY_DISPLAY_SOURCE_STORAGE_KEY = "currencyDisplaySource";
const CURRENCY_DISPLAY_LIST_STORAGE_KEY = "currencyDisplayList";

const useCurrencyManager = (props: CurrencyManagerProps) => {
    const selectableCurrencyDisplayTypes = SELECTABLE_CURRENCY_DISPLAY_TYPES;

    const [currencyDisplayType, setCurrencyDisplayType] =
        useLocalStorage<HexagonalDisplayType>(
            CURRENCY_DISPLAY_TYPE_COOKIE,
            isMobile()
                ? HexagonalDisplayType.BASIC_CENTERED
                : HexagonalDisplayType.RADIAL_CENTERED
        );

    const [currencyRateDivisionType, setCurrencyRateDivisionType] = useState(
        CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION
    );

    const [currencyDisplaySourceStored, setCurrencyDisplaySourceStored] =
        useLocalStorage<string>(
            CURRENCY_DISPLAY_SOURCE_STORAGE_KEY,
            INITIAL_CENTRAL_CURRENCY
        );

    const [currencyDisplaySource, setCurrencyDisplaySource] = useState(
        currencyDisplaySourceStored
    );


    const [currencyDisplayDestinationsStored, setCurrencyDisplayDestinationsStored] = useLocalStorage<string[]>(
        CURRENCY_DISPLAY_LIST_STORAGE_KEY,
        INITIAL_DISPLAY_CURRENCY_LIST
    );

    const [currencyDisplayDestinations, setCurrencyDisplayDestinations] =
        useState(currencyDisplayDestinationsStored);

    const [currencyVisualizationData, setCurrencyVisualizationData] =
        useState<CurrencyVisualizationData>({
            type: "",
            sourceCurrency: { entity: currencyDisplaySource },
            destinationCurrencies: [],
            currencyRateDivisionType: currencyRateDivisionType,
            date: undefined,
        });

    const [selectableCurrencyPool, setSelectableCurrencyPool] = useState<
        CurrencyPoolOption[]
    >([]);

    const [cachedResponse, setCachedResponse] = useState(null);

    const [colorPalette, setColorPalette] = useLocalStorage<string[]>(
        COLOR_PALETTE_COOKIE,
        [...GridAreaSimilarColors]
    );

    const theme = useTheme();
    const clonedDefaultUnitPolygon = { ...DEFAULT_UNIT_POLYGON };
    clonedDefaultUnitPolygon.fillColor = theme.palette.background.paper;
    const [unitPolygon, setUnitPolygon] = useState(clonedDefaultUnitPolygon);

    const [settingsEnabled, setSettingsEnabled] = useState(false);

    const [isCookiePolicyDismissed, setIsCookiePolicyDismissed] =
        useLocalStorage<boolean>(IS_COOKIE_POLICY_DISMISSED, false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    function formatEmptyCurrencySlots(
        targetDestinationCurrencies: string[],
        currencyVisualizationData: CurrencyVisualizationData
    ) {
        for (let i = targetDestinationCurrencies.length; i < 6; i++) {
            currencyVisualizationData.destinationCurrencies.push({
                entity: "---",
                value: 0.0,
                dailyChange: 0.0,
                dailyChangeRate: 0.0,
            });
        }
    }

    function processCurrencyData(currencySourceData: CurrencySourceData) {
        const sourceDate = new Date(currencySourceData.date);
        let responseBaseCurrency = currencySourceData.main;
        let responseRateMap: { [key: string]: ExchangeRateNode } = {};
        let responseCurrencyPool: CurrencyPoolOption[] = [];
        if (Array.isArray(currencySourceData.rates)) {
            // Check if response.data.rates is an array
            currencySourceData.rates.forEach((rate) => {
                responseRateMap[rate.den] = {
                    entity: rate.den,
                    value: parseFloat(rate.rt),
                    dailyChange: parseFloat(rate.dChg),
                    dailyChangeRate: parseFloat(rate.dChgR),
                };
                responseCurrencyPool.push({
                    key: rate.den,
                    text: rate.den + " (" + getCurrencyExplanation(rate.den) + ")",
                    value: rate.den,
                });
            });
        }

        responseCurrencyPool.sort(function (a, b) {
            let textA = a.key.toUpperCase();
            let textB = b.key.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        setSelectableCurrencyPool(responseCurrencyPool);

        let targetSourceCurrency = currencyDisplaySource;
        let targetDestinationCurrencies = currencyDisplayDestinations;

        let currencyVisualizationData: CurrencyVisualizationData = {
            type: "",
            sourceCurrency: { entity: "" },
            destinationCurrencies: [],
            currencyRateDivisionType: currencyRateDivisionType,
            date: sourceDate,
        };

        let exchangeRateMap: { [key: string]: ExchangeRateNode } = {};

        currencyVisualizationData.type = currencyDisplayType;
        if (targetSourceCurrency === responseBaseCurrency) {
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;
            exchangeRateMap = responseRateMap;
        } else if (targetSourceCurrency in responseRateMap) {
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;

            let convertedRateMap: { [key: string]: ExchangeRateNode } = {};
            let currencyConverter = responseRateMap[targetSourceCurrency].value;
            let currencyConverterPrevious =
                responseRateMap[targetSourceCurrency].value -
                responseRateMap[targetSourceCurrency].dailyChange;
            if (Array.isArray(currencySourceData.rates)) {
                // Check if response.data.rates is an array
                currencySourceData.rates.forEach((rate) => {
                    let value = parseFloat(rate.rt) / currencyConverter;
                    let previousValue = parseFloat(rate.rt) - parseFloat(rate.dChg);
                    let previousRate = 1 / (currencyConverterPrevious / previousValue);
                    convertedRateMap[rate.den] = {
                        entity: rate.den,
                        value: value,
                        dailyChange: value - previousRate,
                        dailyChangeRate: (value - previousRate) / value,
                    };
                });
            }
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;

            exchangeRateMap = convertedRateMap;
        }

        targetDestinationCurrencies.forEach((value) => {
            if (value in exchangeRateMap) {
                const exchangeRateNode = exchangeRateMap[value];

                const tempEntity = exchangeRateNode.entity;
                let rateValue = exchangeRateNode.value;
                let tempDailyChange = exchangeRateNode.dailyChange;
                const tempDailyChangeRate = exchangeRateNode.dailyChangeRate;

                if (
                    currencyRateDivisionType ===
                    CURRENCY_RATE_DIVISION_TYPES.CONVERSION_DIV_MAIN
                ) {
                    const previousValue =
                        exchangeRateNode.value - exchangeRateNode.dailyChange;
                    tempDailyChange = 1 / rateValue - 1 / previousValue;
                    currencyVisualizationData.destinationCurrencies.push({
                        entity: tempEntity,
                        value: 1 / rateValue,
                        dailyChange: 1 / rateValue - 1 / previousValue,
                        dailyChangeRate:
                            (1 / rateValue - 1 / previousValue) / (1 / rateValue),
                    });
                } else if (
                    currencyRateDivisionType ===
                    CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION
                ) {
                    currencyVisualizationData.destinationCurrencies.push({
                        entity: tempEntity,
                        value: rateValue,
                        dailyChange: tempDailyChange,
                        dailyChangeRate: tempDailyChangeRate,
                    });
                }
            }
        });

        formatEmptyCurrencySlots(
            targetDestinationCurrencies,
            currencyVisualizationData
        );

        return currencyVisualizationData;
    }

    useEffect(() => {
        async function fetchCurrencyData() {
            if (cachedResponse !== null) {
                let currencyVisualizationData = processCurrencyData(cachedResponse);
                setCurrencyVisualizationData(currencyVisualizationData);
            } else {
                axios
                    .get(
                        `https://zr1bi3gvbl.execute-api.us-east-1.amazonaws.com/prod/currency`
                    )
                    .then((response) => {
                        let currencyVisualizationData = processCurrencyData(response.data);
                        setCurrencyVisualizationData(currencyVisualizationData);
                        setCachedResponse(response.data);
                    });
            }
        }

        fetchCurrencyData();
    }, [
        props,
        currencyDisplaySource,
        currencyDisplayDestinations,
        currencyDisplayType,
        colorPalette,
        cachedResponse,
        currencyRateDivisionType,
        unitPolygon,
        theme,
    ]);

    useEffect(() => {
        const updatedUnitPolygon = {
            ...unitPolygon,
            fillColor: theme.palette.background.paper,
        };
        setUnitPolygon(updatedUnitPolygon);
    }, [theme]);
    return {
        currencyVisualizationData,
        selectableCurrencyPool,
        currencyDisplaySource,
        setCurrencyDisplaySource,
        currencyDisplayDestinations,
        setCurrencyDisplayDestinations,
        currencyDisplayType,
        setCurrencyDisplayType,
        selectableCurrencyDisplayTypes,
        colorPalette,
        setColorPalette,
        unitPolygon,
        settingsEnabled,
        setSettingsEnabled,
        isCookiePolicyDismissed,
        setIsCookiePolicyDismissed,
        currencyDivisionType: currencyRateDivisionType,
        setCurrencyDivisionType: setCurrencyRateDivisionType,
        mode: props.mode,
        setMode: props.setMode,
        setCurrencyDisplaySourceStored,
        isDialogOpen,
        setIsDialogOpen,
        setCurrencyDisplayDestinationsStored,
    };
};

const CurrencyManager: React.FC<CurrencyManagerProps> = (props) => {
    const {
        currencyVisualizationData,
        selectableCurrencyPool,
        currencyDisplaySource,
        setCurrencyDisplaySource,
        currencyDisplayDestinations,
        setCurrencyDisplayDestinations,
        currencyDisplayType,
        setCurrencyDisplayType,
        selectableCurrencyDisplayTypes,
        colorPalette,
        setColorPalette,
        unitPolygon,
        settingsEnabled,
        setSettingsEnabled,
        isCookiePolicyDismissed,
        setIsCookiePolicyDismissed,
        currencyDivisionType,
        setCurrencyDivisionType,
        mode,
        setMode,
        setCurrencyDisplaySourceStored,
        isDialogOpen,
        setIsDialogOpen,
        setCurrencyDisplayDestinationsStored,
    } = useCurrencyManager(props);

    const toggleTheme = () => {
        const newMode =
            mode === ColorTheme.LIGHT ? ColorTheme.DARK : ColorTheme.LIGHT;
        setMode(newMode);
    };

    return (
        <>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar
                        variant="dense"
                        //disableGutters
                        sx={{ minHeight: 48, height: 48 }}
                    >
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Exchange UI
                        </Typography>

                        <IconButton onClick={toggleTheme}>
                            {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ marginBottom: 2 }} />

            <Box sx={{ flexGrow: 1 }}>
                {currencyDisplayType === HexagonalDisplayType.RADIAL_GRID && (
                    <CurrencyGridDisplay
                        type={currencyDisplayType}
                        currencyVisualizationData={currencyVisualizationData}
                        polygonCountLength={13}
                        polygonCountHeight={13}
                        defaultUnitPolygon={unitPolygon}
                        colorPalette={colorPalette}
                    />
                )}

                {currencyDisplayType === HexagonalDisplayType.RADIAL_CENTERED && (
                    <CurrencyCenteredDisplay
                        type={currencyDisplayType}
                        currencyVisualizationData={currencyVisualizationData}
                        edgeLength={7}
                        defaultUnitPolygon={unitPolygon}
                        colorPalette={colorPalette}
                    />
                )}

                {currencyDisplayType === HexagonalDisplayType.BASIC_CENTERED && (
                    <CurrencyCenteredDisplay
                        type={currencyDisplayType}
                        currencyVisualizationData={currencyVisualizationData}
                        edgeLength={2}
                        defaultUnitPolygon={unitPolygon}
                        colorPalette={colorPalette}
                    />
                )}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ColorPaletteSelector
                        colorPalettes={ALL_COLOR_PALETTES}
                        setColorPalette={setColorPalette}
                        activeColorPalette={colorPalette}
                    />
                    <HexagonalDisplayTypeMenu
                        setValue={setCurrencyDisplayType}
                        options={selectableCurrencyDisplayTypes}
                        value={currencyDisplayType}
                    />
                </Box>
            </Box>

            <DateInfoHeader date={currencyVisualizationData.date} />

            <CurrencySearchSelection
                setValue={setCurrencyDisplaySource}
                options={selectableCurrencyPool}
                value={currencyDisplaySource}
            />
            <CurrencyMultipleSearchSelection
                setValue={setCurrencyDisplayDestinations}
                options={selectableCurrencyPool}
                value={currencyDisplayDestinations}
            />

            <div
                style={{
                    width: "90%",
                    margin: "0.75% auto",
                    //paddingTop: '5px',
                }}
            >
                <Stack direction="row" spacing={2}>
                    <CurrencySwitchButton
                        currencyDivisionType={currencyDivisionType}
                        setCurrencyDivisionType={setCurrencyDivisionType}
                    />

                    <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
                        Save
                    </Button>
                </Stack>
            </div>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Save</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to save the changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsDialogOpen(false);
                            setCurrencyDisplaySourceStored(currencyDisplaySource);
                            setCurrencyDisplayDestinationsStored(currencyDisplayDestinations);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <ECBDataSourceInformer />
            <CookiePolicyMessage
                isCookiePolicyDismissed={isCookiePolicyDismissed}
                setIsCookiePolicyDismissed={setIsCookiePolicyDismissed}
            />
        </>
    );
};

export default CurrencyManager;
