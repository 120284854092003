import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const CookiePolicy: React.FC = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button 
            variant="contained" 
            onClick={() => setOpen(true)}
            >
                Show Cookie Policy
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="cookie-policy-title"
                aria-describedby="cookie-policy-description"
            >
                <DialogTitle id="cookie-policy-title">Cookie Policy</DialogTitle>
                <DialogContent>
                    <DialogContentText id="cookie-policy-description">
                        We use cookies to improve your experience on our website, analyze our website traffic, and understand where our visitors are coming from. By browsing our website, you consent to our use of cookies and other tracking technologies.
                    </DialogContentText>
                    <DialogContentText>
                        A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
                    </DialogContentText>
                    <DialogContentText>
                        You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CookiePolicy;