type CurrencyInfo = {
  code: string;
  name: string;
}

export const currencyInfo: CurrencyInfo[] = [
  { code: "AFN", name: "Afghan Afghani" },
  { code: "ALL", name: "Albanian Lek" },
  { code: "DZD", name: "Algerian Dinar" },
  { code: "AOA", name: "Angolan Kwanza" },
  { code: "ARS", name: "Argentine Peso" },
  { code: "AMD", name: "Armenian Dram" },
  { code: "AWG", name: "Arubian Florin" },
  { code: "AUD", name: "Australian Dollar" },
  { code: "AZN", name: "Azerbaijani Manat" },
  { code: "BSD", name: "Bahamian Dollar" },
  { code: "BHD", name: "Bahrain Dinar" },
  { code: "BDT", name: "Bangladeshi Taka" },
  { code: "BIF", name: "Burundian Franc" },
  { code: "VUV", name: "Vanuatu Vatu" },
  { code: "BBD", name: "Barbados Dollar" },
  { code: "BYN", name: "Belarusian Ruble" },
  { code: "BZD", name: "Belize Dollar" },
  { code: "BMD", name: "Bermuda Dollar" },
  { code: "BTN", name: "Bhutanese Ngultrum" },
  { code: "BOB", name: "Bolivian Boliviano" },
  { code: "BAM", name: "Bosnian Convertible Mark" },
  { code: "BWP", name: "Botswana Pula" },
  { code: "BRL", name: "Brazilian Real" },
  { code: "BND", name: "Brunei Dollar" },
  { code: "BGN", name: "Bulgarian Leva" },
  { code: "BUK", name: "Burmese Kyat" },
  { code: "KHR", name: "Cambodian Riel" },
  { code: "CVE", name: "Cape Verdean Escudo" },
  { code: "KYD", name: "Cayman Islands Dollar" },
  { code: "XAF", name: "CFA Franc" },
  { code: "XPF", name: "CFP Franc" },
  { code: "CLP", name: "Chilean Pesos" },
  { code: "COP", name: "Colombian Peso" },
  { code: "KMF", name: "Comorian Franc" },
  { code: "CDF", name: "Congolese Franc" },
  { code: "CRC", name: "Costa Rican Colon" },
  { code: "HRK", name: "Croatian Kuna" },
  { code: "CUP", name: "Cuban Peso" },
  { code: "CZK", name: "Czech Koruna" },
  { code: "DKK", name: "Danish Kroner" },
  { code: "DJF", name: "Dijiboutian Franc" },
  { code: "DOP", name: "Dominican Peso" },
  { code: "XCD", name: "Eastern Caribbean Dollar" },
  { code: "EGP", name: "Egyptian Pounds" },
  { code: "ERN", name: "Eritrean Nakfa" },
  { code: "ETB", name: "Ethiopia Birr" },
  { code: "FKP", name: "Falkland Island Pound" },
  { code: "FOK", name: "Faroese Krona" },
  { code: "FJD", name: "Fijian Dollar" },
  { code: "GMD", name: "Gambian Dalasi" },
  { code: "GEL", name: "Georgian Lari" },
  { code: "GHS", name: "Ghanaian Cedi" },
  { code: "GIP", name: "Gibraltar Pound" },
  { code: "GTQ", name: "Guatemalan Quetzal" },
  { code: "GGP", name: "Guernsey Pound" },
  { code: "GNF", name: "Guinean Franc" },
  { code: "GYD", name: "Guyanese Dollar" },
  { code: "HTG", name: "Haitian Gourde" },
  { code: "HNL", name: "Honduran Lempira" },
  { code: "HKD", name: "Hong Kong Dollar" },
  { code: "HUF", name: "Hungarian Forint" },
  { code: "ISK", name: "Icelandic Krona" },
  { code: "INR", name: "Indian Rupee" },
  { code: "IDR", name: "Indonesian Rupiah" },
  { code: "IRR", name: "Iranian Rial" },
  { code: "IQD", name: "Iraqi Dinar" },
  { code: "ILS", name: "Israeli Shekel" },
  { code: "JMD", name: "Jamaican Dollar" },
  { code: "JOD", name: "Jordanian Dinar" },
  { code: "KZT", name: "Kazakhstani Tenge" },
  { code: "KES", name: "Kenyan Shilling" },
  { code: "KWD", name: "Kuwaiti Dinar" },
  { code: "KGS", name: "Kyrgyzstani Som" },
  { code: "LAK", name: "Lao Kip" },
  { code: "LBP", name: "Lebanese Pound" },
  { code: "LSL", name: "Lesotho Loti" },
  { code: "LRD", name: "Liberian Dollar" },
  { code: "LYD", name: "Libyan Dinar" },
  { code: "MKD", name: "Macedonian Denar" },
  { code: "MGA", name: "Malagasy Ariary" },
  { code: "MWK", name: "Malawian Kwacha" },
  { code: "MYR", name: "Malaysian Ringitt" },
  { code: "MVR", name: "Maldivian Rufiyaa" },
  { code: "MRU", name: "Mauritanian Ouguiya" },
  { code: "MUR", name: "Mauritian Rupee" },
  { code: "MXN", name: "Mexican Peso" },
  { code: "MDL", name: "Moldovan Leu" },
  { code: "MNT", name: "Mongolian Tugrik" },
  { code: "MAD", name: "Moroccan Dirham" },
  { code: "MZN", name: "Mozambican Metical" },
  { code: "NAD", name: "Namibian Dollar" },
  { code: "NPR", name: "Nepalese Rupee" },
  { code: "ANG", name: "Netherlands Antillean Guilder" },
  { code: "TWD", name: "New Taiwan Dollar" },
  { code: "NIO", name: "Nicaraguan Dollar" },
  { code: "NGN", name: "Nigerian Naira" },
  { code: "KPW", name: "North Korean Won" },
  { code: "OMR", name: "Omani Rials" },
  { code: "PKR", name: "Pakistani Rupee" },
  { code: "PGK", name: "Papua New Guinean Kina" },
  { code: "PYG", name: "Paraguayan Guarani" },
  { code: "PEN", name: "Peruvian Sol" },
  { code: "PHP", name: "Philippine Peso" },
  { code: "PLN", name: "Polish Zloty" },
  { code: "QAR", name: "Qatari Riyal" },
  { code: "RON", name: "Romanian Leu" },
  { code: "RUB", name: "Russian Rubles" },
  { code: "RWF", name: "Rwandan Franc" },
  { code: "SHP", name: "Saint Helena Pound" },
  { code: "WST", name: "Samoan Tala" },
  { code: "STN", name: "Sao Tome and Principe Dobra" },
  { code: "SAR", name: "Saudi Riyal" },
  { code: "RSD", name: "Serbian Dinar" },
  { code: "SCR", name: "Seychellois Rupee" },
  { code: "SLL", name: "Sierra Leonean Leone" },
  { code: "SGD", name: "Singapore Dollars" },
  { code: "SBD", name: "Solomun Island Dollar" },
  { code: "SOS", name: "Somali Shillings" },
  { code: "ZAR", name: "South African Rand" },
  { code: "KRW", name: "South Korean Won" },
  { code: "SSP", name: "South Sudanese Pound" },
  { code: "LKR", name: "Sri Lankan Rupee" },
  { code: "SDG", name: "Sudanese Pound" },
  { code: "SRD", name: "Surinamese Dollar" },
  { code: "SZL", name: "Swazi Lilangeni" },
  { code: "SEK", name: "Swedish Krona" },
  { code: "SYP", name: "Syrian Pound" },
  { code: "TJS", name: "Tajikistani Somoni" },
  { code: "TZS", name: "Tanzanian Shilling" },
  { code: "THB", name: "Thai Baht" },
  { code: "TOP", name: "Tongan Pa'anga" },
  { code: "TTD", name: "Trinidad and Tobago Dollar" },
  { code: "TND", name: "Tunisian Dinars" },
  { code: "TMT", name: "Turkmen Manat" },
  { code: "AED", name: "UAE Dirhams" },
  { code: "UGX", name: "Ugandan Shilling" },
  { code: "UAH", name: "Ukranian Hryvnia" },
  { code: "UYU", name: "Uruguayan Peso" },
  { code: "UZS", name: "Uzbekistani Som" },
  { code: "VES", name: "Venezuelan Bolivar" },
  { code: "VND", name: "Vietnamese Dong" },
  { code: "XOF", name: "West African Franc" },
  { code: "YER", name: "Yemen Rial" },
  { code: "ZMW", name: "Zambian Kwacha" },
  { code: "USD", name: "United States Dollar" },
  { code: "TRY", name: "Turkish Lira" },
  { code: "EUR", name: "Euro" },
  { code: "GBP", name: "Pound Sterling" },
  { code: "JPY", name: "Japanese Yen" },
  { code: "CNY", name: "Chinese Yuan" },
  { code: "NOK", name: "Norwegian Krone" },
  { code: "CAD", name: "Canadian Dollar" },
  { code: "CHF", name: "Swiss Franc" },
  { code: "NZD", name: "New Zealand Dollar" },
  { code: "EMPTY", name: "Empty" },
  { code: "---", name: "Empty" },
];

const currencyMap = new Map<string, CurrencyInfo>();
currencyInfo.forEach((currency) => {
  currencyMap.set(currency.code, currency);
});

export function getCurrencyExplanation(sourceCurrencyEntity: string): string {
  let currency: CurrencyInfo | undefined = currencyMap.get(sourceCurrencyEntity);
  if (currency) {
    return currency.name;
  } else {
    return "Unknown Currency";
  }
}
export const INITIAL_DISPLAY_CURRENCY_LIST = ["TRY", "EUR", "GBP", "JPY", "CNY", "NOK"];
export const INITIAL_CENTRAL_CURRENCY = "USD";

