import React, { SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import './CurrencySearchSelection.css';
import { CurrencyPoolOption } from '../../../types/CurrencyTypes';

interface CurrencySearchSelectionProps {
  setValue: (value: string) => void;
  options: CurrencyPoolOption[];
  value: string;
}

const CurrencySearchSelection: React.FC<CurrencySearchSelectionProps> = (props) => {
  const handleChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) {
      props.setValue(value);
    }
  };

  return (
    <div className="CurrencySearchSelection">
      <Autocomplete
        size='small'
        options={props.options.map(option => option.value)}
        getOptionLabel={(option) => props.options.find((o) => o.value === option)?.text || ''}
        value={props.value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField 
          {...params} 
          label="Main Currency"
          placeholder="Main Currency" 
          variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default CurrencySearchSelection;