import { HexagonalDisplayType } from "../Util/PolygonUtil";


export const SELECTABLE_CURRENCY_DISPLAY_TYPES = [
    { key: HexagonalDisplayType.BASIC_CENTERED, text: 'Basic', value: HexagonalDisplayType.BASIC_CENTERED },
    { key: HexagonalDisplayType.RADIAL_CENTERED, text: 'Hexagonal', value: HexagonalDisplayType.RADIAL_CENTERED },
    { key: HexagonalDisplayType.RADIAL_GRID, text: 'Wide', value: HexagonalDisplayType.RADIAL_GRID },
];

export enum CURRENCY_RATE_DIVISION_TYPES {
    MAIN_DIV_CONVERSION = "MAIN_DIV_CONV",
    CONVERSION_DIV_MAIN = "CONV_DIV_MAIN",
}