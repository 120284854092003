import React, { useEffect, useState } from 'react'
import PolygonUtils, { UnitPolygon } from "../../../Util/PolygonUtil";
import PolygonInnerText from "../PolygonInnerText/PolygonInnerText";
import './RegularConvexPolygon.css';

interface ExtendedUnitPolygon extends UnitPolygon {
    widthRatio: number;
}

function formatPoints(points: number[][]): string {
    return points.map(pair => pair.join(',')).join(' ');
}

const useRegularConvexPolygon = (props: ExtendedUnitPolygon) => {

    const [widthRatio, setWidthRatio] = useState(props.widthRatio);
    const [radius, setRadius] = useState(30);
    const [edgeOffsetRatio, setEdgeOffsetRatio] = useState(props.edgeOffsetRatio);
    const [startAngle, setStartAngle] = useState(props.startAngle);
    const [numSides, setNumSide] = useState(props.numSides);
    const [fillColor, setFillColor] = useState(props.fillColor);
    const [strokeColor, setStrokeColor] = useState(props.strokeColor);
    const [text, setText] = useState(props.text);
    const [textFontSize, setTextFontSize] = useState(props.textFontSize);
    const [innerPolygonRatio, setInnerPolygonRatio] = useState(props.innerPolygonRatio);
    const [innerFillColor, setInnerFillColor] = useState(props.innerFillColor);
    const [renderingOrder, setRenderingOrder] = useState(props.renderingOrder);

    const [centerAng, setCenterAngle] = useState(2 * Math.PI / numSides);
    const [edgeOffsetLen, setEdgeOffsetLen] = useState(radius * edgeOffsetRatio / 2);

    const tempGeneratedPoints = PolygonUtils.generatePoints(
        numSides,
        radius,
        centerAng,
        startAngle);

    let generatedPointsOuter = PolygonUtils.generatePoints(
        numSides,
        radius + edgeOffsetLen,
        centerAng,
        startAngle);

    const tempGeneratedPointsInner = PolygonUtils.generatePoints(
        numSides,
        (radius + edgeOffsetLen) * innerPolygonRatio,
        centerAng,
        startAngle);


    const [edges, setEdges] = useState(PolygonUtils.getEdgePoints(generatedPointsOuter));
    

    const [generatedPoints, setGeneratedPoints] = useState(PolygonUtils.getShiftedPositiveQuadrant(tempGeneratedPoints, edges));
    const [generatedPointsInner, setGeneratedPointsInner] = useState(PolygonUtils.getShiftedPositiveQuadrant(tempGeneratedPointsInner, edges));

    const [polygonCoordinates, setPolygonCoordinates] = useState(formatPoints(generatedPoints));
    const [polygonCoordinatesInner, setPolygonCoordinatesInner] = useState(formatPoints(generatedPointsInner));
    
    let dimensions = PolygonUtils.getDimensions(edges);
    const [xDim, setXDim] = useState(dimensions.xDim);
    const [yDim, setYDim] = useState(dimensions.yDim);

    useEffect(() => {

        
        const tempCenterAng = 2 * Math.PI / props.numSides;
        const tempEdgeOffsetLen = radius * props.edgeOffsetRatio / 2;

        setWidthRatio(props.widthRatio);
        setFillColor(props.fillColor);
        setEdgeOffsetRatio(props.edgeOffsetRatio);
        setStartAngle(props.startAngle);
        setNumSide(props.numSides);
        setFillColor(props.fillColor);
        setStrokeColor(props.strokeColor);
        setText(props.text);
        setTextFontSize(props.textFontSize);
        setInnerPolygonRatio(props.innerPolygonRatio);
        setInnerFillColor(props.innerFillColor);
        setRenderingOrder(props.renderingOrder);
        setCenterAngle(tempCenterAng);
        setEdgeOffsetLen(tempEdgeOffsetLen);
        
        const tempGeneratedPoints = PolygonUtils.generatePoints(
            props.numSides,
            radius,
            tempCenterAng,
            startAngle);
        let tempGeneratedPointsOuter = PolygonUtils.generatePoints(
            props.numSides,
            radius + tempEdgeOffsetLen,
            tempCenterAng,
            startAngle);

        const tempGeneratedPointsInner = PolygonUtils.generatePoints(
            props.numSides,
            (radius + tempEdgeOffsetLen) * props.innerPolygonRatio,
            tempCenterAng,
            startAngle);
           
        
        const tempEdges = PolygonUtils.getEdgePoints(tempGeneratedPointsOuter);
        setEdges(tempEdges);


        const tempShiftedPoints = PolygonUtils.getShiftedPositiveQuadrant(tempGeneratedPoints, tempEdges);
        const tempShiftedPointsInner = PolygonUtils.getShiftedPositiveQuadrant(tempGeneratedPointsInner, tempEdges);
        
        setGeneratedPoints(tempShiftedPoints);
        setGeneratedPointsInner(tempShiftedPointsInner);

        setPolygonCoordinates(formatPoints(tempShiftedPoints));
        setPolygonCoordinatesInner(formatPoints(tempShiftedPointsInner));

        let dimensions = PolygonUtils.getDimensions(tempEdges);
        setXDim(dimensions.xDim);
        setYDim(dimensions.yDim);
        

    }, [
        props


    ]);

    return {
        widthRatio,
        edgeOffsetRatio,
        startAngle,
        numSides,
        centerAng,
        generatedPoints,
        edgeOffsetLen,
        edges,
        xDim,
        yDim,
        polygonCoordinates,
        polygonCoordinatesInner,
        fillColor,
        strokeColor,
        text,
        textFontSize,
        innerPolygonRatio,
        innerFillColor,
        renderingOrder,
    };
}

const RegularConvexPolygon: React.FC<ExtendedUnitPolygon> = (props) => {
    const {
        widthRatio,
        edgeOffsetRatio,
        startAngle,
        numSides,
        centerAng,
        generatedPoints,
        edgeOffsetLen,
        edges,
        xDim,
        yDim,
        polygonCoordinates,
        polygonCoordinatesInner,
        fillColor,
        strokeColor,
        text,
        textFontSize,
        innerPolygonRatio,
        innerFillColor,
        renderingOrder,
    } = useRegularConvexPolygon(props);

    const animationClass = `visibleIn${renderingOrder}`;

    return (
        <svg
            width={(widthRatio * 100) + "%"}
            viewBox={"0 0 " + xDim + " " + yDim}
            style={{ verticalAlign: 'top' }}
            className={animationClass}
        >
            <polygon points={polygonCoordinates} style={{
                fill: fillColor,
                stroke: strokeColor,
                strokeWidth: (100 * edgeOffsetRatio) + "%",
                verticalAlign: 'top'
            }} />
            <polygon points={polygonCoordinatesInner} style={{
                fill: innerFillColor,
                verticalAlign: 'top'
            }} />
            <PolygonInnerText text={text} />
        </svg>
    );
}

export default RegularConvexPolygon;