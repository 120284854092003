import React from 'react';
import { Snackbar, Button } from '@mui/material';
import CookiePolicy from '../../atoms/CookiePolicy/CookiePolicy';

interface CookiePolicyMessageProps {
    isCookiePolicyDismissed: boolean;
    setIsCookiePolicyDismissed: (isCookiePolicyDismissed: boolean) => void;
}

const CookiePolicyMessage: React.FC<CookiePolicyMessageProps> = (props): React.ReactElement | null => {
    const { isCookiePolicyDismissed, setIsCookiePolicyDismissed } = props;

    return (
        !isCookiePolicyDismissed ? (
            <Snackbar
                open={!isCookiePolicyDismissed}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={
                    'This website uses cookies to improve your experience, analyze traffic, and personalize content. You can control your cookie preferences in your browser settings.'
                }
                action={
                    <>
                        <Button
                            variant='contained'
                            onClick={() => setIsCookiePolicyDismissed(true)}
                            sx={{ marginRight: 2 }}
                        >
                            Got it!
                        </Button>
                        <CookiePolicy />
                    </>
                }
            />
        ) : null
    );
}

export default CookiePolicyMessage;