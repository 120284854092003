import RegularConvexPolygon from "../../atoms/RegularConvexPolygon/RegularConvexPolygon";
import React, { useState } from "react";
import { PolygonType, UnitPolygon } from "../../../Util/PolygonUtil";
import { useWindowDimensions } from "../../../Util/useWindowDimensions";
import { AxialArray } from "../../../Util/HexagonGridUtils";

interface PolygonGroupProps {
    polygonCountLength: number;
    polygonCountHeight: number;
    axialArray: AxialArray;
    axialMap: { [key: string]: UnitPolygon }; // Replace 'any' with the actual type
}

function getPolygonGroupType(axialArray: AxialArray) {
    let arrayLength = axialArray.length;
    let i = 0;
    let isCentered = false;
    while (i < arrayLength && !isCentered) {
        if (arrayLength !== axialArray[i].length) {
            isCentered = true;
        }
        i++;
    }
    
    if (isCentered) {
        return PolygonType.CENTERED;
    } else {
        return PolygonType.GRID;
    }
}

const usePolygonGroup = (props: PolygonGroupProps) => {

    let length = props.polygonCountLength;
    let height = props.polygonCountHeight;
    length = length % 2 === 1 ? length : length + 1;
    height = height % 2 === 1 ? height : height + 1;

    const [polygonCountLength] = useState(length);
    const [polygonCountHeight] = useState(height);

    const [axialArray] = useState(props.axialArray);
    const [axialMap] = useState(props.axialMap);

    const [widthRatio] = useState(1 / polygonCountLength);

    let layoutType = getPolygonGroupType(axialArray);

    const [groupLayoutType] = useState(layoutType);

    const screenWidthRatio = useWindowDimensions().currencyDisplayScreenWidthRatio;

    return {
        polygonCountLength,
        polygonCountHeight,
        axialArray,
        axialMap,
        widthRatio,
        groupLayoutType,
        screenWidthRatio
    };
}

function generateRectangleGridMargins(axialArray: AxialArray,
    index: number,
    dynamicStyle: React.CSSProperties,
    horizontalMargin: number,
    verticalMargin: number) {
    if (axialArray.length === index + 1) {
        dynamicStyle = {
            marginRight: horizontalMargin + "%",
        };
    } else if (index % 2 === 0) {
        if (index === 0) {
            dynamicStyle = {
                marginRight: horizontalMargin + "%",
                marginBottom: "-" + verticalMargin + "%",
            };
        } else {
            dynamicStyle = {
                marginRight: horizontalMargin + "%",
                marginBottom: "-" + verticalMargin + "%",
            };
        }
    } else {
        dynamicStyle = {
            marginLeft: horizontalMargin + "%",
            marginBottom: "-" + verticalMargin + "%",
        };
    }
    return dynamicStyle;
}

function generateCenteredGridMargins(polygonCountLength: number,
    row: string[],
    tempWidthRatio: number,
    axialArray: AxialArray,
    index: number,
    dynamicStyle: React.CSSProperties,
    horizontalMargin: number,
    verticalMargin: number) {
    let space = polygonCountLength - row.length;

    tempWidthRatio = 1 / row.length;
    if (axialArray.length === index + 1) {
        dynamicStyle = {
            marginLeft: (horizontalMargin * space) + "%",
            marginRight: (horizontalMargin * space) + "%",
        };
    } else if (index % 2 === 0) {
        if (index === 0) {
            dynamicStyle = {
                marginLeft: (horizontalMargin * space) + "%",
                marginBottom: "-" + verticalMargin + "%",
                marginRight: (horizontalMargin * space) + "%",
            };
        } else {
            dynamicStyle = {
                marginLeft: (horizontalMargin * space) + "%",
                marginBottom: "-" + verticalMargin + "%",
                marginRight: (horizontalMargin * space) + "%",
            };
        }
    } else {
        dynamicStyle = {
            marginLeft: (horizontalMargin * space) + "%",
            marginBottom: "-" + verticalMargin + "%",
            marginRight: (horizontalMargin * space) + "%",
        };
    }
    return { tempWidthRatio, dynamicStyle };
}

const PolygonGroup: React.FC<PolygonGroupProps> = (props) => {
    const {
        polygonCountLength,
        polygonCountHeight,
        axialArray,
        axialMap,
        widthRatio,
        groupLayoutType,
        screenWidthRatio
    } = usePolygonGroup(props);

    let horizontalMargin = 0;
    let verticalMargin = 0;

    if (groupLayoutType === PolygonType.GRID) {
        horizontalMargin = (1 / ((polygonCountLength * 2) + 1)) * 100;
        verticalMargin = horizontalMargin / Math.sqrt(3)
    } else if (groupLayoutType === PolygonType.CENTERED) {
        horizontalMargin = (1 / ((polygonCountLength * 2))) * 100;
        verticalMargin = horizontalMargin / Math.sqrt(3)
    }

    return (
        <div style={{
            width: screenWidthRatio,
            overflowX: "auto",
            overflowY: "auto",
            margin: "0 auto"
        }}>
            {
                axialArray.map((row: string[], index: number) => {
                    let dynamicStyle = {};
                    let tempWidthRatio = widthRatio;
                    if (groupLayoutType === PolygonType.GRID) {
                        dynamicStyle = generateRectangleGridMargins(axialArray, index, dynamicStyle, horizontalMargin, verticalMargin);
                        tempWidthRatio = widthRatio;
                    } else if (groupLayoutType === PolygonType.CENTERED) {
                        let __ret = generateCenteredGridMargins(polygonCountLength, row, tempWidthRatio, axialArray, index, dynamicStyle, horizontalMargin, verticalMargin);
                        tempWidthRatio = __ret.tempWidthRatio;
                        dynamicStyle = __ret.dynamicStyle;
                    }
                    return <div
                        style={dynamicStyle}>{
                            row.map((element, index) => <RegularConvexPolygon widthRatio={tempWidthRatio} {...axialMap[element]}
                            />)
                        }</div>
                }
                )
            }
        </div>
    );
}
export default PolygonGroup;