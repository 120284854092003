import { useEffect, useState } from "react";
import { initHexagonPolygonCenteredGrid } from "../../../Util/HexagonGridUtils";
import PolygonGroup from "../../molecules/PolygonGroup/PolygonGroup";
import {
    ClockwiseHexagonDirections,
    ClockwiseHexagonRegions,
    Directions, getDirectionalOrientations,
    getRegionalOrientations,
    HexagonalDisplayType,
    OrientationOffset,
    UnitPolygon
} from "../../../Util/PolygonUtil";
import { detailedNumberFormatter, largeNumberFormatter } from "../../../Util/NumberFormattingUtil";
import { lightenDarkenColor } from "../../../Util/ColorUtil";
import { CurrencyVisualizationData, ExchangeRateNode } from "../../../types/CurrencyTypes";
import { prepareCenterPolygon } from "../../../Util/CurrencyDisplayUtil";
import { decorateCurrencyDisplayPolygons } from "../../../Util/CurrencyDisplayUtil";
import { CURRENCY_RATE_DIVISION_TYPES } from "../../../constants/DisplayTypes";

interface CurrencyCenteredDisplayProps {
    type: HexagonalDisplayType;
    currencyVisualizationData: CurrencyVisualizationData;
    edgeLength: number;
    defaultUnitPolygon: UnitPolygon;
    colorPalette: string[];
}

function formatDailyChangeString(currency: ExchangeRateNode) {
    let changeDataString = "";
    if (currency.dailyChange > 0) {
        changeDataString = "+" + detailedNumberFormatter(currency.dailyChange) +
            "(+" + largeNumberFormatter(currency.dailyChangeRate * 100) + "%)";
    } else {
        changeDataString = detailedNumberFormatter(currency.dailyChange) +
            "(" + largeNumberFormatter(currency.dailyChangeRate * 100) + "%)";
    }
    return changeDataString;
}

const useCurrencyCenteredDisplay = (props: CurrencyCenteredDisplayProps) => {
    const [edgeLength, setEdgeLength] = useState(props.edgeLength);
    const [defaultUnitPolygon, setDefaultUnitPolygon] = useState(props.defaultUnitPolygon);

    let { tempAxialArray, tempAxialMap } = initHexagonPolygonCenteredGrid(edgeLength, defaultUnitPolygon);

    const [axialArray, setAxialArray] = useState(tempAxialArray);
    const [axialMap, setAxialMap] = useState(tempAxialMap);

    const [colorPalette, setColorPalette] = useState(props.colorPalette);



    useEffect(() => {

        const currencyVisualizationData = props.currencyVisualizationData;

        const type = props.type;
        const sourceCurrencyEntity = currencyVisualizationData.sourceCurrency.entity;
        const destinationCurrencies = currencyVisualizationData.destinationCurrencies;

        if (sourceCurrencyEntity === "") {
            return;
        }
        let clonedAxialMap = { ...axialMap };

        //iterate over clonedAxialMap
        Object.keys(clonedAxialMap).forEach((key) => {
            clonedAxialMap[key].fillColor = props.defaultUnitPolygon.fillColor;
        });

        if (type === HexagonalDisplayType.RADIAL_CENTERED) {

            const colorPalette = props.colorPalette;

            let regionalOrientations = getRegionalOrientations();

            let centerPolygonCoordinate = regionalOrientations[Directions.CENTER]?.x + "," + regionalOrientations[Directions.CENTER]?.y;

            prepareCenterPolygon(sourceCurrencyEntity, clonedAxialMap[centerPolygonCoordinate]);

            destinationCurrencies.forEach((currency, currencyIndex) => {
                const direction = ClockwiseHexagonRegions[currencyIndex];
                const currencyColor = colorPalette[currencyIndex];

                decorateCurrencyDisplayPolygons(
                    direction,
                    currency,
                    currencyColor,
                    clonedAxialMap,
                    sourceCurrencyEntity,
                    defaultUnitPolygon,
                    type,
                    currencyVisualizationData.currencyRateDivisionType,
                );
            });
        } else if (type === HexagonalDisplayType.BASIC_CENTERED) {
            const colorPalette = props.colorPalette;

            let directionalOrientations = getDirectionalOrientations();

            let mainColor = "#cacaca";

            let centerPolygonCoordinate = directionalOrientations[Directions.CENTER]?.x + "," + directionalOrientations[Directions.CENTER]?.y;
            clonedAxialMap[centerPolygonCoordinate].fillColor = mainColor;
            clonedAxialMap[centerPolygonCoordinate].strokeColor = mainColor;
            clonedAxialMap[centerPolygonCoordinate].innerFillColor = mainColor;
            clonedAxialMap[centerPolygonCoordinate].text = sourceCurrencyEntity;


            destinationCurrencies.forEach((currency, index) => {
                let direction = ClockwiseHexagonDirections[index];
                let polygonCoordinate = directionalOrientations[direction]?.x + "," + directionalOrientations[direction]?.y;
                let displayValue = currency.value;

                const currencyColor = colorPalette[index];

                let currencyText = currency.entity;
                let fillColor = props.defaultUnitPolygon.fillColor;
                let strokeColor = props.defaultUnitPolygon.strokeColor;
                let innerFillColor = currencyColor;
                clonedAxialMap[polygonCoordinate].fillColor = innerFillColor;
                let textBlockColor = lightenDarkenColor(innerFillColor, 30);

                const rateDivisionText = currencyVisualizationData.currencyRateDivisionType === CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION ?
                    sourceCurrencyEntity + "/" + currencyText : currencyText + "/" + sourceCurrencyEntity;

                if (displayValue !== 0) {
                    let currencyValueString = detailedNumberFormatter(displayValue);
                    let changeDataString = formatDailyChangeString(currency);
                    clonedAxialMap[polygonCoordinate].text =
                        rateDivisionText + "\n"
                        + currencyValueString + "\n"
                        + changeDataString;
                } else {
                    clonedAxialMap[polygonCoordinate].text =
                        "\n  " + sourceCurrencyEntity + "/" + currencyText + "  \n";
                }
                clonedAxialMap[polygonCoordinate].strokeColor = strokeColor;
            });
        }
        setAxialMap(clonedAxialMap);

    }, [props, colorPalette]);

    return {
        edgeLength,
        axialArray,
        axialMap,
    };
}

const CurrencyCenteredDisplay = (props: CurrencyCenteredDisplayProps) => {
    const {
        edgeLength,
        axialArray,
        axialMap,
    } = useCurrencyCenteredDisplay(props);

    return (
        <>
            <PolygonGroup
                polygonCountLength={2 * edgeLength - 1}
                polygonCountHeight={2 * edgeLength - 1}
                axialArray={axialArray}
                axialMap={axialMap}
            />
        </>
    );
}
export default CurrencyCenteredDisplay;




