import React from 'react';
import { Typography, Box } from '@mui/material';

interface DateInfoHeaderProps {
    date: Date | undefined;
}

const DateInfoHeader: React.FC<DateInfoHeaderProps> = ({ date }) => {
    return (
        <Box mt={2}>
        <Typography
            align="center"
        >
            Date of the info: {date !== undefined ? date.toISOString().substring(0, 10) : "---"}
        </Typography>
        </Box>
    );
}

export default DateInfoHeader;