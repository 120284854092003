import { useState, useEffect } from "react";

interface WindowDimensions {
    width: number;
    height: number;
    currencyDisplayScreenWidthRatio: string;
}

export function useWindowDimensions(): WindowDimensions {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
        width: window.innerWidth,
        height: window.innerHeight,
        currencyDisplayScreenWidthRatio: getCurrencyDisplayScreenWidthRatio(
            window.innerWidth,
            window.innerHeight
        ),
    });

    useEffect(() => {
        const handleResize = () => {
            if (window) {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                    currencyDisplayScreenWidthRatio: getCurrencyDisplayScreenWidthRatio(
                        window.innerWidth,
                        window.innerHeight
                    ),
                });
            }
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

function getCurrencyDisplayScreenWidthRatio(innerWidth: number, innerHeight: number): string {
    const heightToWidthRatio = innerHeight / innerWidth;
    let calculatedWidth = 100;
    if (heightToWidthRatio < 1.0) {
        calculatedWidth *= 0.9 * heightToWidthRatio;
    } else {
        calculatedWidth *= 0.9;
    }
    return calculatedWidth + "%";
}