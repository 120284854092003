import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { HexagonalDisplayType } from '../../../Util/PolygonUtil';

interface HexagonalDisplayTypeMenuProps {
    setValue: (value: HexagonalDisplayType) => void;
    options: { key: HexagonalDisplayType; text: string; value: HexagonalDisplayType }[];
    value: HexagonalDisplayType;
}

const HexagonalDisplayTypeMenu: React.FC<HexagonalDisplayTypeMenuProps> = (props) => {

    const handleChange = (event: SelectChangeEvent) => {
        props.setValue(event.target.value as HexagonalDisplayType);
    };

    return (
        <FormControl variant="standard" sx={{ m: 1 }}>
            <Select
                size='small'
                labelId="view-type-label"
                value={props.value}
                onChange={handleChange}
                renderValue={() => 'View'}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default HexagonalDisplayTypeMenu;