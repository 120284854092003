import PolygonGroup from "../../molecules/PolygonGroup/PolygonGroup";
import React, { useEffect, useState } from "react";
import { ClockwiseHexagonRegions, Directions, getRegionalOrientations, HexagonalDisplayType, OrientationOffset, UnitPolygon } from "../../../Util/PolygonUtil";
import { initHexagonPolygonRectangleGrid, getRadialExpansionSequence, AxialMap } from "../../../Util/HexagonGridUtils";
import { getDiagonalStepValue } from "../../../Util/NumberFormattingUtil";
import { lightenDarkenColor } from "../../../Util/ColorUtil";
import { CurrencyVisualizationData } from "../../../types/CurrencyTypes";
import { CentralBlockColor, GridAreaContrastColors } from "../../../constants/DisplayColors";
import { decorateCurrencyDisplayPolygons, generatePolygonRegionColorSet, setFilledInfoPolygon, setInitialCurrencyInfoPolygon } from "../../../Util/CurrencyDisplayUtil";
import { setFinalCurrencyInfoPolygon } from "../../../Util/CurrencyDisplayUtil";
import { setEmptyCurrencyGridInfoPolygon } from "../../../Util/CurrencyDisplayUtil";
import { prepareCenterPolygon } from "../../../Util/CurrencyDisplayUtil";

interface CurrencyGridDisplayProps {
    polygonCountLength: number;
    polygonCountHeight: number;
    defaultUnitPolygon: UnitPolygon;
    currencyVisualizationData: CurrencyVisualizationData;
    type: HexagonalDisplayType;
    colorPalette: string[];
}

const useCurrencyGridDisplay = (props: CurrencyGridDisplayProps) => {
    const [polygonCountLength] = useState(props.polygonCountLength);
    const [polygonCountHeight] = useState(props.polygonCountHeight);
    const [defaultUnitPolygon] = useState(props.defaultUnitPolygon);

    let { tempAxialArray, tempAxialMap } = initHexagonPolygonRectangleGrid(polygonCountLength, polygonCountHeight, defaultUnitPolygon);

    const [axialArray] = useState(tempAxialArray);
    const [axialMap, setAxialMap] = useState(tempAxialMap);

    const [colorPalette, setColorPalette] = useState(props.colorPalette);


    useEffect(() => {

        const currencyVisualizationData = props.currencyVisualizationData;

        const type = props.type;
        const sourceCurrencyEntity = currencyVisualizationData.sourceCurrency.entity;
        const destinationCurrencies = currencyVisualizationData.destinationCurrencies;

        if (sourceCurrencyEntity === "") {
            return;
        }
        let clonedAxialMap: AxialMap = { ...axialMap };

        Object.keys(clonedAxialMap).forEach((key) => {
            clonedAxialMap[key].fillColor = props.defaultUnitPolygon.fillColor;
        });


        if (type === HexagonalDisplayType.RADIAL_GRID) {

            const colorPalette = props.colorPalette;

            const regionalOrientations = getRegionalOrientations();

            const centerPolygonCoordinate = regionalOrientations[Directions.CENTER]?.x + "," + regionalOrientations[Directions.CENTER]?.y;

            prepareCenterPolygon(sourceCurrencyEntity, clonedAxialMap[centerPolygonCoordinate]);

            destinationCurrencies.forEach((currency, currencyIndex) => {
                const direction = ClockwiseHexagonRegions[currencyIndex];
                const currencyColor = colorPalette[currencyIndex];

                decorateCurrencyDisplayPolygons(
                    direction, 
                    currency, 
                    currencyColor, 
                    clonedAxialMap, 
                    sourceCurrencyEntity, 
                    props.defaultUnitPolygon,
                    type,
                    currencyVisualizationData.currencyRateDivisionType
                );
            });
        }
        setAxialMap(clonedAxialMap);

    }, [props]);
    return {
        polygonCountLength,
        polygonCountHeight,
        axialArray,
        axialMap,
    };
}

const CurrencyGridDisplay = (props: CurrencyGridDisplayProps) => {
    const {
        polygonCountLength,
        polygonCountHeight,
        axialArray,
        axialMap,
    } = useCurrencyGridDisplay(props);

    return (
        <>
            <PolygonGroup
                polygonCountLength={polygonCountLength}
                polygonCountHeight={polygonCountHeight}
                axialArray={axialArray}
                axialMap={axialMap}
            />
        </>

    );

}
export default CurrencyGridDisplay;

