import { FC } from "react";
import { Box, Typography, Link } from "@mui/material";

const ECBDataSourceInformer: FC = () => {
    return (
        <Box
            className="ECBDataSourceInformer"
            sx={{
                width: '90%',
                margin: '1% auto',
                paddingBottom: '1%',
            }}
        >
            <Typography variant="body1">
                The financial data presented on this website is sourced from the
                European Central Bank (ECB). This publicly available information can be
                freely accessed through their official website:
                <Link
                    href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    European Central Bank (ECB)
                </Link>
                .
            </Typography>
        </Box>
    );
};

export default ECBDataSourceInformer;
