import { UnitPolygon } from "../Util/PolygonUtil";

export const DEFAULT_UNIT_POLYGON: UnitPolygon = {
    edgeOffsetRatio: 0.046,
    startAngle: 90,
    numSides: 6,
    fillColor: '#ffffff',
    strokeColor: '#777777',
    text: "",
    textFontSize: 14,
    innerPolygonRatio: 0,
    innerFillColor: "",
    renderingOrder: 0
};
